import { useEffect } from 'react'
// STYLES
import '../styles/App.css'
import '../styles/bootstrap.min.css'
import '../styles/helpers.min.css'
import '../styles/homepage.min.css'
import '../styles/landing.min.css'
// COMPONENTS
import { ReactSVG } from 'react-svg'
import {ReactComponent as AppStoreBadge} from '../images/app_store_badge.svg'

function Home() {

  useEffect(() => {
    document.title = 'Switup - Entraînements de musculation et nutrition'
  }, [])

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark pb_navbar pb_scrolled-light" id="pb-navbar">
            <div class="container">
                <a class="navbar-brand" href="#">
                    <img src={require('../images/abdologo-white-white.png')} height="40" />
                </a>
                <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#probootstrap-navbar" aria-controls="probootstrap-navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span><i class="ion-navicon"></i></span>
                </button>
                <div class="collapse navbar-collapse" id="probootstrap-navbar">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item"><a class="nav-link" href="#section-home">Accueil</a></li>
                        <li class="nav-item"><a class="nav-link" href="#section-features">Fonctionnalités</a></li>
                        <li class="nav-item"><a class="nav-link" href="#section-reviews">Avis</a></li>
                        <li class="nav-item"><a class="nav-link" href="#section-pricing">Tarifs</a></li>
                        <li class="nav-item"><a class="nav-link" href="#section-faq">FAQ</a></li>
                    </ul>
                </div>
            </div>
        </nav>

        <section class="pb_cover_v3 overflow-hidden cover-bg-indigo cover-bg-opacity text-left pb_gradient_v1 pb_slant-light" id="section-home">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-6">
                        <h2 class="heading mb-3">
                            <img src={require('../images/switup-white.png')} height="70" />
                        </h2>
                        <div class="sub-heading">
                            <p class="mb-4">Atteignez vos objectifs grâce à Switup.<br />Créez des programmes personnalisés parmi des centaines d'exercices accessibles gratuitement.<br />Découvrez des recettes spécialement conçues pour vous.</p>
                            <a target="_blank" style={{textDecoration: 'none'}} href="https://play.google.com/store/apps/details?id=com.switup">
                                <img alt="Disponible sur Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png" height="52" style={{marginRight: 10}} />
                            </a>
                            <a target="_blank" style={{textDecoration: 'none'}} href="https://apps.apple.com/fr/app/switup-suivi-dentra%C3%AEnement/id1551956246">
                                <AppStoreBadge />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb_section bg-light" id="section-features">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 order-2">
                        <img src={require('../images/phone_1_fr.png')} alt="Application mobile Switup" class="img-fluid" />
                    </div>
                    <div class="col-lg-8 pr-md-5 pr-sm-0 order-1  mb-5">
                        <div class="row">
                            <div class="col">
                                <h2>Fonctionnalités</h2>
                                <p class="pb_font-20">Atteindre vos objectifs n'aura jamais été si facile avec les fonctionnalités Switup.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg">

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-arrow-graph-up-right pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Progression</h3>
                                        <p class="text-sans-serif pb_font-16">Suivez vos progrès après chaque séance grâce à des statistiques détaillées qui vous montreront votre évolution.</p>
                                    </div>
                                </div>

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-ios-people pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Partage</h3>
                                        <p class="text-sans-serif pb_font-16">Partagez vos progrès avec vos amis, des récompenses sont là pour vous aider à atteindre vos objectifs et à rester motivé.</p>
                                    </div>
                                </div>

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-flag pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Objectifs</h3>
                                        <p class="text-sans-serif pb_font-16">Perdre du poids, prendre du muscle,.. Définissez des objectifs et suivez leur progression dans le temps grâce à un suivi personnalisé.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg">

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-ios-color-wand pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Personnalisation</h3>
                                        <p class="text-sans-serif pb_font-16">Réalisez vous-même vos programmes adaptés à votre niveau grâce à des exercices avec ou sans matériel, ou utilisez ceux des autres.</p>
                                    </div>
                                </div>

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-ios-nutrition pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Nutrition</h3>
                                        <p class="text-sans-serif pb_font-16">Sèche, prise de masse ou perte de poids ? Des conseils pour vous aider à bien vous alimenter pour de meilleurs résultats.</p>
                                    </div>
                                </div>

                                <div class="media pb_feature-v2 text-left mb-1 mt-5">
                                    <div class="pb_icon d-flex mr-3 align-self-start pb_w-15"><i class="ion-android-phone-portrait pb_icon-gradient"></i></div>
                                    <div class="media-body">
                                        <h3 class="mt-2 mb-2 heading">Synchronisation</h3>
                                        <p class="text-sans-serif pb_font-16">Retrouvez vos entraînements et votre progression où que vous soyez. Vos progrès sont synchronisés sur chaque appareil.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="pb_xl_py_cover overflow-hidden pb_gradient_v1">
            <div class="container">
                <div class="row align-items-center justify-content-center" id="signup">
                    <div class="col-md-5 justify-content-center">
                        <h2 class="heading mb-5 pb_font-40">Rejoignez Switup dès aujourd'hui !</h2>
                        <div class="sub-heading">
                            <p class="mb-4">Atteignez vos objectifs grâce à Switup.<br />Créez des programmes personnalisés parmi des centaines d'exercices accessibles gratuitement.<br />Découvrez des recettes spécialement conçues pour vous.</p>
                            <a target="_blank" style={{textDecoration: 'none'}} href="https://play.google.com/store/apps/details?id=com.switup">
                                <img alt="Disponible sur Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png" height="52" style={{marginRight: 10}} />
                            </a>
                            <a target="_blank" style={{textDecoration: 'none'}} href="https://apps.apple.com/fr/app/switup-suivi-dentra%C3%AEnement/id1551956246">
                                <AppStoreBadge height={56} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="pb_footer bg-light" role="contentinfo">
            <div class="container">
                <div class="row text-center">
                    <div class="col">
                        <ul class="list-inline">
                            <li class="list-inline-item"><a href="https://www.facebook.com/switupfrance" class="p-2">Facebook</a></li>
                            <li class="list-inline-item"><a href="https://www.instagram.com/switupfrance" class="p-2">Instagram</a></li>
                            <li class="list-inline-item"><a href="https://twitter.com/switupfrance" class="p-2">Twitter</a></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center">
                        <p class="pb_font-14">Switup &copy; {new Date().getFullYear()}. Tous droits réservés.</p>
                        <div class="legal">
                            <a href="https://switup.com/terms-of-service">Conditions générales d'utilisation</a>
                            <span>•</span>
                            <a href="mailto:contact@switup.com">Nous contacter</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Home
