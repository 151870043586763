import { useEffect } from 'react'
import '../styles/App.css'

function Support() {

  useEffect(() => {
    window.location.href = 'mailto:contact@switup.com'
  }, [])

  return (<div></div>)
}

export default Support
