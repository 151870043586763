import { useEffect, useState } from 'react'
import '../styles/App.css'
import { initializeApp } from 'firebase/app'
import {
  collection,
  getFirestore,
  getCountFromServer
} from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyC783ehOpLIAb_di3WxdkYF2ksSFZup5Bo",
  authDomain: "switup.firebaseapp.com",
  databaseURL: "https://switup-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "switup",
  storageBucket: "switup.appspot.com",
  messagingSenderId: "102735817559",
  appId: "1:102735817559:web:3c5dc81ea5fcf7c909ac12",
  measurementId: "G-HRMKPLMPEX"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)

function Admin() {

  const [count, setCount] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [user, setUser] = useState()

  useEffect(() => {
    const countUsers = async () => {
      try {
      const coll = collection(db, 'users')
      const snapshot = await getCountFromServer(coll)
      setCount(snapshot.data().count)
      } catch (e) {
        setError(e)
        console.error(e)
      }
    }

    if (user)
      countUsers()
  }, [user])

  const signIn = () => {
    signInWithEmailAndPassword(auth, email, password).then(userCredential => {
      setUser(userCredential.user)
      setError('bien ouej')
    }).catch(e => setError(e))
  }

  if (!user) {
    return (
      <div className="App">
        <header className="App-header">
          <input name="email" onChange={e => setEmail(e.target.value)} type="email" value={email} />
          <input name="password" onChange={e => setPassword(e.target.value)} type="password" value={password} />
          <button onClick={signIn}>Connexion</button>
          {error ?
            <p style={{color: 'red'}}>{error.message}</p>
          : null}
        </header>
      </div>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Nombre d'utilisateurs : {count ?? '?'}
        </p>
        {error ?
          <p style={{color: 'red'}}>{error.message}</p>
        : null}
      </header>
    </div>
  )
}

export default Admin
