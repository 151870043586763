import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import privacyPolicy from '../data/privacyPolicy.md'

function PrivacyPolicy() {

  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    document.title = 'Switup - Privacy policy'

    fetch(privacyPolicy).then(response => {
      return response.text()}
    ).then(text => {
      setMarkdown(text)
    })
  }, [])

  return (
    <div style={{padding: '20px'}}>
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    </div>
  )
}

export default PrivacyPolicy
